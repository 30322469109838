document.addEventListener('DOMContentLoaded', function(){
    eventListeners();
});

function eventListeners(){

    const mobileMenu = document.querySelector('.mobile-menu');

    mobileMenu.addEventListener('click', navegacionResponsive);

    const metodoPrecio = document.querySelectorAll('input[name="variante[preferencia]"]');
    metodoPrecio.forEach(input =>input.addEventListener('click', mostrarPrecio));

    const metodoContacto = document.querySelectorAll('input[name="contacto[preferencia]"]');
    metodoContacto.forEach(input =>input.addEventListener('click', mostrarContacto));

    const cfdi = document.getElementById('cfdi');
    cfdi.addEventListener('change',mostrarArchivos);
}
function mostrarContacto(e){
    const contactoDiv = document.querySelector('#contacto');
    console.log(e.target.value);
    if(e.target.value === 'telefono'){
        contactoDiv.innerHTML = `
        <label for="telefono">Telefono  </label>
        <input type="tel" placeholder="Tu telefono" id="telefono" name = "contacto[telefono]">
        <p>Elija la fecha y la hora de la llamada</p>

        <label for="fecha">Fecha</label>
        <input type="date" id="fecha" name = "contacto[fecha]">

        <label for="hora">Hora:</label>
        <input type="time" id="hora" min="09:00" max="18:00" name = "contacto[hora]">

        `;
    }else{
        contactoDiv.innerHTML = `
        <label for="email">E-mail</label>
        <input type="email" placeholder="Tu Email" id="email" name = "contacto[email]" required>
        `;
    }
}
function mostrarPrecio(e){
    const secondPrice = document.querySelector('.secondPrice');
    console.log(e.target.value);
    if(e.target.value === 'sivariante'){
        secondPrice.innerHTML = `
        <label for="precio">Precio:</label>
        <input type="number" id="precio" name="servicio[pricevariant]" placeholder="Precio Servicio" value="<?php echo  sanitizar($servicio->pricevariant); ?>">    
        `;
    }
    if(e.target.value === 'novariante'){
        secondPrice.innerHTML = `
        `;
    }
}
function navegacionResponsive (){
    const navegacion = document.querySelector('.navegacion');
    if(navegacion.classList.contains('mostrar')){
        navegacion.classList.remove('mostrar');
    }
    else{
        navegacion.classList.add('mostrar');
    }
}
let filesArray = [];
function mostrarArchivos(){
    const fileList = document.getElementById('fileList');
    console.log(filesArray);
    for (let i = 0; i < this.files.length; i++) {
        const file = this.files[i];
        const listItem = document.createElement('div');
        listItem.textContent = 'Archivo seleccionado: ' + file.name + ' (' + Math.round(file.size / 1024) + ' KB)';
        fileList.appendChild(listItem);
        filesArray.push(this.files[i]);
    }
    // Crear un nuevo objeto FileList para el input de archivo
    const dataTransfer = new DataTransfer();
    filesArray.forEach(file => dataTransfer.items.add(file));
    document.getElementById('cfdi').files = dataTransfer.files;
}

function guardarArchivos(){

}

